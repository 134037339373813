
import { RESET_PASSWORD_MUTATION } from "@/constants/gql/user";
import { useMutation } from "@vue/apollo-composable";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "ValidationCode",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const validationHash = route.params.code;
    const error = ref<string>("");
    const password = ref<string>("");
    const confirmPassword = ref<string>("");
    const { mutate } = useMutation(RESET_PASSWORD_MUTATION);
    const windowWidth = ref<number>(window.innerWidth);
    const resetPasswordError = ref<string>("");

    const resizeScreen = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(async () => {
      window.addEventListener("resize", resizeScreen);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeScreen);
    });

    const resetPassword = async () => {
      if (password.value.length < 8 && confirmPassword.value.length < 8) {
        error.value = "Your password should be at least 8 characters";
      } else if (password.value != confirmPassword.value) {
        error.value = "New passwords doesn't match";
      } else {
        const { data } = await mutate({
          validationHash,
          newPassword: password.value
        });
        if (data && data.resetPassword == "success") {
          resetPasswordError.value = data.resetPassword;
          error.value = "";
          router.push("/");
        } else {
          error.value = "An error occured";
        }
      }
    };

    return {
      resetPassword,
      resetPasswordError,
      validationHash,
      error,
      password,
      confirmPassword,
      windowWidth
    };
  }
});
